.dropdown {
  &-item {
    font-weight: $font-weight-regular;

    &.active {
      color: $primary;
    }
  }

  &-text {
    position: relative;

    &:hover,
    &:focus,
    &:active {
      &::before {
        position: absolute;
        right: 0;
        bottom: -5px;
        left: 0;

        display: block;

        height: 1px;

        content: '';

        background-color: $dark;
      }
    }
  }
}
