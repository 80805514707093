.text-and-bullets {
  .list {
    position: relative;

    list-style: none; /* Remove default bullets */

    li {
      margin-bottom: $size-xs;
      padding-left: rem(8px);

      &::before {
        font-weight: bold; /* If you want it to be bold */

        position: absolute;
        left: 2rem;

        display: inline-block; /* Needed to add space between the bullet and the text */

        transform: translateX(-100%);
        // margin-right: 1rem; /* Also needed for space (tweak if needed) */
        // margin-left: -2rem;
        text-align: right;

        color: $secondary; /* Change the color */
      }
    }
  }
}
