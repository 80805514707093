.gallery {
  .card {
    &-image {
      img {
        display: block;

        margin: 0 auto;
      }
    }

    .headline {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }
  }
}
