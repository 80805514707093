.google-maps {
  &.secondary {
    .row {
      [class|='col'] {
        &:first-child {
          padding: 0;
        }
      }
    }

    .headline {
      padding: 0;

      &::after {
        display: none;
      }
    }

    .google-maps-info {
      display: flex;
      flex-direction: column;

      flex-grow: 1;
      align-items: center;
      justify-content: center;
    }
  }
}
