.fact-sheet {
  &-item {
    .headline {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }
  }
}
