.gallery {
  &.slider {
    .slick-track {
      display: flex !important;
      overflow: hidden;

      align-items: center !important;
    }

    .slider-item {
      img {
        margin: 0 auto;
      }
    }
  }
}

[id|='gallery-slider'] {
  .popover-content {
    transform: translateY(50%);
  }
}
