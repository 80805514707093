.navbar {
  flex-direction: column; // for toplinks

  min-height: 5rem;

  background-color: rgba(255,255,255, 1);
  box-shadow: $box-shadow-lg, inset 0 rem(1.5px) rem(3px) rgba(0, 0,  0, 0.12);

  justify-content: center; // for toplinks
  @include media-breakpoint-down(xxl) {
    .links {
      flex-direction: column;    // need to change position earlier, default is md

      margin-top: 0;

      align-items: flex-start;  // need to change position earlier, default is md

      > * {
        margin-top: 1.25rem;
      }
    }
  }

  > [class*=container] {
    flex-wrap: nowrap;
  }

  // .toggler {
  //   &-open {
  //     opacity: 0;
  //   }

  //   &-close {
  //     opacity: 1;
  //   }
  // }

  // &-active {

  // }

  &-brand {
    // flex-shrink: 0;
    flex-shrink: 1;

    .img {
      width: auto;
      max-height: 3.125rem;
    }
  }

  &-toggler {
    position: relative;
    z-index: 50;  //over back link

    display: block;

    width: $hamburger-width;
    height: $hamburger-height;
    margin-left: 15px;

    cursor: pointer;
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);

    border: 0;
    background: transparent;

    flex-shrink: 0;
    @include media-breakpoint-up(xxl) {
      display: none;
    }

    span {
      position: absolute;
      right: 0;

      display: block;

      width: 100%;
      height: $hamburger-item-heaight;

      -webkit-transition: 0.15s ease-in-out;
      -moz-transition: 0.15s ease-in-out;
      -o-transition: 0.15s ease-in-out;
      transition: 0.15s ease-in-out;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);

      opacity: 1;
      border-radius: 9px;
      background: $hamburger-color;

      &:nth-child(1) {
        top: 2px;

        transform: rotateZ(-45deg);
        transform-origin: right center;
      }

      &:nth-child(2) {
        top: ($hamburger-height /2 ) - $hamburger-item-heaight/2 ;

        width: 0;
      }

      &:nth-child(3) {
        top: $hamburger-height - $hamburger-item-heaight - 2;

        width: 100%;

        transform: rotateZ(45deg);
        transform-origin: right center;
      }
    }

    &.collapsed {
      span {
        &:nth-child(1) {
          top: 5px;

          transform: none;
        }

        &:nth-child(2) {
          top: ($hamburger-height /2 ) - $hamburger-item-heaight/2 ;

          width: 72%;
        }

        &:nth-child(3) {
          top: $hamburger-height - $hamburger-item-heaight - 5;

          width: 85%;

          transform: none;
        }
      }
    }
  }

  &-collapse {
    @include media-breakpoint-down(xxl) {
      position: fixed;
      top: 80px;                                     // 80px - height of navbar
      right: 0;
      // bottom: 0;
      left: 0;
      // height: 100%;

      border-top: 1px solid $gray-400 ;
      background: $white;
    }

    &-inner {
      display: flex;

      width: 100%;

      justify-content: flex-end;
      align-items: center;
      @include media-breakpoint-down(xxl) {
        display: block;
        overflow: scroll;

        max-width: 720px;
        height: calc(100vh - 80px);                 // 80px - height of navbar
        margin: 0 auto;
        padding-top: rem(32px);
        padding-right: var(--bs-gutter-x, 0.75rem); // this, above and margin + max-width are to mimic .container
        padding-bottom: rem(32px);                  // can't set padding on collapse (leads to a broken animation)
        padding-left: var(--bs-gutter-x, 0.75rem);
      }
      @include media-breakpoint-down(md) {
        max-width: 540px;
      }
    }
  }

  &-nav {
    flex-wrap: wrap;
    @include media-breakpoint-down(xxl) {
      .dropdown {
        &-menu {
          margin-top: 0;
          padding: 0;

          border: none;
          box-shadow: none;
        }

        li {
          &:last-child {
            padding-bottom: 1.25rem;              // need to add it to last li in dropdown to make equal space on top and bottom
          }
        }

        &-item {
          padding: 0.75rem 1rem;
        }

        &-toggle {
          &::after,
          &::before {
            position: absolute;
            top: 50%;
            right: 0;
            left: auto  !important;             // overriding Bootstrap to set right position to ::before

            display: block !important;          // overriding Bootstrap to always show ::before

            width: 20px;
            height: 2px;
            margin-top: -1px;                   // because we use transform to rotate it's better to use margin-top with height / 2 here

            content: '';

            border: none;
            background-color: $dark;
          }

          &::before {
            transition: transform 0.15s ease-in-out;
            transform: rotateZ(90deg);
          }

          &.show {
            &::before {
              transform: rotateZ(0);
            }
          }
        }
      }

      .nav-item {
        border-bottom: 1px solid $gray-400;
      }

      .nav-link {
        padding: 1.25rem 0;
      }
    }

    .dropdown {
      &-toggle {
        &::after {
          display: none;
        }
      }
    }

    .nav-link {
      font-family: 'Raleway', sans-serif;
      font-weight: $font-weight-medium;

      &.active {
        font-weight: $font-weight-semi-bold;
      }

      &:hover,
      &:focus,
      &:active {
        &::before {
          display: none;
        }
      }
    }
  }

  &-toplinks {
    width: 100%;
    margin-top: -0.5rem; // navbar padding top/bottom
    margin-bottom: 10px;
    padding: 12px 0;

    background-color: #f9f9f9;

    @include media-breakpoint-down(xxl) {
      margin-top: 26px;
    }
    @include media-breakpoint-up(xxl) {
      + .container {
        padding: 8px 0; // to add more height to the main navbar when toplinks are present
      }
    }

    .toplinks {
      display: flex;

      margin-bottom: 0;
      padding: 0;

      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      @include media-breakpoint-down(xxl) {
        flex-direction: column;

        align-items: flex-start;
      }

      &-link {
        font-size: rem(12px);
        font-weight: $font-weight-semi-bold;

        transition: color 0.25s ease-in-out;

        color: $brand-dark;

        flex-shrink: 0;
        @include media-breakpoint-down(xxl) {
          margin-left: 0 !important;

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }


        &:not(:first-child) {
          margin-left: 23px;
        }

        &:hover,
        &:focus,
        &:active {
          color: shade-color($brand-dark, 25%);
        }
      }
    }
  }

  &-buttons {
    display: flex;

    align-items: center;
  }

  &-search {
    margin-left: 15px;
    padding: 5px;

    transition: color 0.25s ease-in-out;

    color: $brand-dark;
    border: none;
    background-color: transparent;

    appearance: none;
    @include media-breakpoint-down(xxl) {
      margin-left: 0;
    }

    &:hover,
    &:focus,
    &:active {
      color: shade-color($brand-dark, 25%);
    }

    &-modal {
      .modal-dialog {
        @include media-breakpoint-up(sm) {
          width: 100%;
          max-width: 870px;
        }
      }

      .modal-content {
        padding: 56px 32px;
      }

      .modal-close {
        position: absolute;
        top: 16px;
        right: 16px;
      }

      .headline {
        font-weight: $font-weight-semi-bold;

        margin-bottom: 16px;
      }

      .search {
        position: relative;

        &-input {
          padding-right: 40px;
        }

        &-button {
          position: absolute;
          top: 50%;
          right: 25px;

          transform: translate(50%, -50%);

          @extend .navbar-search;
        }
      }
    }
  }
}
