.stage {
  &.single-side {
    display: flex;

    align-items: center;

    .container {
      .row {
        @include media-breakpoint-down(md) {
          &::before {
            background-color: rgba(0,0,0,0.75);
            background-image: none !important;
          }
        }

        &::before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;

          display: block;

          content: '';

          opacity: 0.35;
          background-image: linear-gradient(to right, #000 0%, rgba(0, 0, 0, 0) 100%);
        }

        &.flex-row-reverse {
          &::before {
            background-image: linear-gradient(to left, #000 0%, rgba(0, 0, 0, 0) 100%);
          }
        }
      }
    }

    .headline {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }
  }
}
