.contact-card {
  .card {
    max-width: 500px;
    margin: 0 auto;

    flex-wrap: wrap;
    @include media-breakpoint-up(lg) {
      flex-direction: row;

      max-width: 100%;
    }

    &-body {
      @include media-breakpoint-up(lg) {
        display: flex;

        align-items: center;
        justify-content: center;
      }

      .headline {
        padding: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  .contact-info {
    max-width: 400px;

    &-item {
      display: flex;

      margin-bottom: rem(12px);

      align-items: center;

      svg {
        margin-right: rem(14px);

        color: $primary;
      }
    }
  }

  .contact-additional {
    margin-bottom: 0;

    list-style-type: none;

    &-item {
      margin-bottom: rem(12px);

      color: $primary;
    }
  }
}
