.link {
  font-family: 'Raleway', sans-serif;
  font-size: $font-size-input-md;       // share the same size as buttons
  font-weight: $font-weight-medium;

  display: flex;

  transition: color 0.15s ease-in-out;

  align-items: center;

  &-sm {
    font-size: $font-size-input-sm;   // share the same size as buttons
  }

  &-lg {
    font-size: $font-size-input-lg;   // share the same size as buttons
  }

  // &:hover {
  //   text-decoration: underline;
  // }

  &-light {
    &:hover,
    &:focus,
    &:active {
      color: shade-color($light, 25%);
    }
  }

  &-divider {
    &::after {
      display: inline-block;

      width: 1px;
      height: 12px;
      margin: 0 10px;

      content: '';

      background-color: currentColor;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }

  &-inline {
    display: inline-flex;

    margin-right: 30px;

    &:last-child {
      margin-right: 0;
    }
  }

  svg {
    transition: transform 0.15s ease-in-out;
  }

  &:hover,
  &:focus,
  &:active {
    svg {
      &.bi-chevron-right {
        transform: translateX(3px);
      }

      &.bi-arrow-up-right {
        transform: translate(3px, -3px);
      }
    }
  }

  &-dark {
    color: $black;

    &:hover,
    &:focus,
    &:active {
      color: tint-color($black, 20%);
    }
  }
}

// .bg_color_second {
//   .link {
//     color: $white;
//   }
// }
