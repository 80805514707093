.text-and-bullets {
  &.icons {
    .list {
      li {
        position: relative;

        &::before {
          display: none;
        }

        svg {
          position: absolute;
          top: 50%;
          left: -0.25rem;

          transform: translate(-100%, -50%);

          color: $secondary;
          // margin-right: 1rem;
          // margin-left: -2rem;
        }
      }
    }
  }
}
