
$brand-primary: #2e58a6;
$brand-secondary: #b5a99e;
$brand-tertiary: #343b52;

$brand-white: #fff;
$brand-black: #000;

$brand-light: #f9f9f9;
$brand-dark: #666;

$brand-success: #28a745;
$brand-danger: #dc3545;
$brand-warning: #ffc107;
$brand-info: #17a2b8;
