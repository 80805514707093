.teaser-and-image {
  &.single-side {
    display: flex;

    align-items: center;

    img {
      border-radius: 0;
    }

    .card {
      background-color: rgba(255, 255, 255, 0.85);

      &-body {
        @include media-breakpoint-up(md) {
          padding: $card-spacer-y * 1.5 $card-spacer-x * 1.5;
        }
      }
    }
  }
}
