.nav {
  &-link {
    font-family: 'Raleway', sans-serif;
    font-weight: $font-weight-medium;

    position: relative;

    // &:hover,
    // &:focus,
    // &:active {
    // &::before {
    //   position: absolute;
    //   right: 12px;
    //   bottom: 5px;
    //   left: 12px;

    //   display: block;

    //   height: 1px;

    //   content: '';

    //   background-color: $dark;
    // }
    // }

    &.active {
      font-weight: $font-weight-semi-bold;

      color: $brand-primary;

      // &:hover,
      // &:focus,
      // &:active {
      //   &::before {
      //     background-color: $brand-primary;
      //   }
      // }
    }
  }
}
