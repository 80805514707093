.features {
  .card {
    background-color: $tertiary;

    .headline {
      padding-bottom: 0;

      color: $white;

      &::after {
        display: none;
      }
    }

    .teaser {
      color: $white;
    }

    a {
      text-decoration: underline;

      color: $white;
    }
  }
}
