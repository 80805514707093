.display-1 {
  line-height: $line-height-display-desktop-1;

  hyphens: initial;     // excluding from global styling to use &shy;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-display-mobile-1;
    line-height: $line-height-display-mobile-1;
  }
}

.display-2 {
  line-height: $line-height-display-desktop-2;

  hyphens: initial;     // excluding from global styling to use &shy;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-display-mobile-2;
    line-height: $line-height-display-mobile-2;
  }
}

.display-3 {
  line-height: $line-height-display-desktop-3;

  hyphens: initial;     // excluding from global styling to use &shy;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-display-mobile-3;
    line-height: $line-height-display-mobile-3;
  }
}

.display-4 {
  line-height: $line-height-display-desktop-4;

  hyphens: initial;     // excluding from global styling to use &shy;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-display-mobile-4;
    line-height: $line-height-display-mobile-4;
  }
}

.display-5 {
  line-height: $line-height-display-desktop-5;

  hyphens: initial;     // excluding from global styling to use &shy;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-display-mobile-5;
    line-height: $line-height-display-mobile-5;
  }
}

.display-6 {
  line-height: $line-height-display-desktop-6;

  hyphens: initial;     // excluding from global styling to use &shy;

  @include media-breakpoint-down(lg) {
    font-size: $font-size-display-mobile-6;
    line-height: $line-height-display-mobile-6;
  }
}
