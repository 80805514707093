.text-and-video {
  transition: box-shadow 0.15s ease-in-out;

  border-radius: $card-border-radius;
  box-shadow: $box-shadow;

  &:hover,
  &:focus,
  &:active {
    box-shadow: $box-shadow-hover;
  }

  // TEXT PART
  .col-xl-4 {
    @include media-breakpoint-up(xl) {
      padding-right: 64px;
    }
  }
}
