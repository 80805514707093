.teaser-and-image {
  &.medium {
    .headline {
      padding: 0;

      &::after {
        display: none;
      }
    }
  }
}
