.downloads {
  a {
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }
  }

  .card {
    height: 100%;

    justify-content: center;

    &-body {
      flex: 0;

      padding-top: $card-spacer-y/2.5;
      padding-right: $card-spacer-x/1.5;
      padding-bottom: $card-spacer-y/2.5;
      padding-left: $card-spacer-x/1.5;
    }

    svg {
      color: $primary;
    }

    .headline {
      padding: 0;

      &::after {
        display: none;
      }
    }
  }
}
