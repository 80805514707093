.news {
  &-list {
    &-date {
      font-weight: $font-weight-regular;
    }

    .news-list {
      padding-bottom: 5px;
    }

    .card {
      .headline {
        padding-bottom: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  &-details {
    .container,
    .container-fluid {
      --bs-gutter-x: 0.75rem;                       // .containers inside .rows have bigger paddings because of how bootstrap
    }                                               // calculates them (.row sets --bs-gutter-x to 1.5 rem and we need to fix that)

    .container-fluid {
      margin-right: calc(var(--bs-gutter-x) * -1);  // because child CE's are inside basic .container-fluid we need to stretch
      margin-left: calc(var(--bs-gutter-x) * -1);   // them to apply background colors for the full width
    }

    .pagination {
      display: flex;

      justify-content: space-between;
      @include media-breakpoint-down(md) {
        flex-direction: column;

        align-items: center;
      }

      .page-item {
        display: flex;

        max-width: 450px;

        flex-grow: 1;
        flex-basis: 0;
        align-items: center;
        @include media-breakpoint-down(md) {
          max-width: 100%;
        }

        &:nth-child(2) {
          justify-content: flex-end;
        }

        a {
          display: flex;

          align-items: center;

          span {
            padding: 10px;
          }

          svg {
            color: $primary;

            flex-shrink: 0;
          }
        }
      }
    }

    .row:nth-child(1) {
      .img-background {
        border-radius: $border-radius-lg;
      }
    }

    .row:nth-child(2) {
      .preline {
        color: $secondary;
      }

      .headline {
        padding-bottom: 0;

        &::after {
          display: none;
        }
      }
    }
  }
}
