.menu-section {
  .links {
    margin-right: 0;
    margin-left: 0;

    > * {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .nav {
    &-link {
      color: $primary;

      &:hover,
      &:focus,
      &:active {
        color: shade-color($primary,10%) !important;
      }
    }
  }
}
