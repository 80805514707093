@import 'Mixins';
@import 'Variables';

@import 'Vendors';

@import 'Base';
@import 'Components';
@import 'Helpers';

.bg_color_main {
  background-color: $brand-light;
}

.bg_color_second {
  background-color: $brand-secondary;
}

.bg_color_main_light {
  background-color: #f5f3f0;
}

.bg_color_main_dark {
  background-color: #ecf0f4;
}

.bg_color_tertiary {
  background-color: $brand-tertiary;
}

.container-fluid {
  overflow: auto; /* safari margin fix */
}

#main {
  padding-top: 0 !important;
}
