// Cards

$card-spacer-y: rem(30px);
$card-spacer-x: rem(30px);
$card-title-spacer-y: $spacer / 2 !default;
$card-border-width: 0;
$card-border-radius: $border-radius;
$card-border-color: rgba($black, 0.125) !default;
$card-inner-border-radius: 0;
$card-cap-padding-y: $card-spacer-y / 2 !default;
$card-cap-padding-x: $card-spacer-x !default;
$card-cap-bg: rgba($black, 0.03) !default;
$card-cap-color: null !default;
$card-height: null !default;
$card-color: null !default;
$card-bg: $white !default;

$card-img-overlay-padding: $spacer !default;

$card-group-margin: $grid-gutter-width / 2 !default;
