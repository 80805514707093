.text-and-bullets {
  &.standard {
    .list {
      li {
        &::before {
          content: '\2022';  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        }
      }
    }
  }
}
