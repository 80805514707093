.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;

  width: 40px;
  height: 40px;

  transition: background-color 0.15s ease-in-out;

  border: none;
  background-color: $secondary;

  appearance: none;

  svg {
    transition: color 0.15s ease-in-out, transform 0.15s ease-in-out;

    color: $white;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: shade-color($secondary, 25%);

    svg {
      transform: translateY(-25%);

      color: shade-color($white, 15%);
    }
  }
}
