.text-and-icon-list {
  &.secondary {
    background-color: $white;
    box-shadow: none;

    hr:not(.color-static),
    .headline:not(.color-static),
    .link:not(.color-static),
    [class|='form']:not(.color-static):not(.form-floating) {
      color: $black !important;
    }

    .preline:not(.color-static) {
      color: $primary !important;
    }

    .teaser:not(.color-static) {
      color: $dark  !important;
    }

    .headline {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }
  }
}
