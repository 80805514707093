.preline {
  font-size: $font-size-text-sm;
  font-weight: $font-weight-regular;

  position: relative;

  hyphens: initial;     // excluding from global styling to use &shy;

  color: $brand-primary;
}

// .bg_color_second {
//   .preline {
//     color: $white;
//   }
// }
