.cta {
  .links {
    justify-content: center;
    @include media-breakpoint-down(md) {
      align-items: center;
    }
  }

  .headline {
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }
}
