.product-cards {
  .card {
    background-color: $tertiary;

    &.active {
      border: 3px solid $primary;
    }

    &.highlight {
      border: 3px solid $secondary;
    }

    .bullets {
      &-item {
        display: flex;

        border-bottom: 1px $gray-400 solid;

        justify-content: space-between;
        align-items: center;
      }

      &-item:first-child {
        border-top: 1px $gray-400 solid;
      }

      &-icon {
        cursor: pointer;

        svg {
          color: $primary;
        }
      }
    }

    .links {
      flex-direction: column;

      align-items: flex-start;
    }

    .headline {
      padding-bottom: 0;

      color: $white;

      &::after {
        display: none;
      }
    }

    .teaser {
      color: $white;
    }
  }
}
